import { Modal } from 'antd';
import React from 'react';
import { Form } from '../form';
import Row from '../row';
import { Button } from '../button';

export const MaintTypes = [
  { title: 'Oil Change', change: 'mileage_loc', value: 'change_oil' },
  { title: 'Tire Change', change: 'mileage_tirechange', value: 'change_tires' },
  { title: 'Brake Job', change: 'mileage_brakes', value: 'change_brakes' },
  { title: 'Other', change: 'other', value: 'change_other' },
]

const required = {
  options: {
    rules: [
      { required: true, }
    ]
  }
}

export const AddressForm = props => {
  const formProps = {
    onSubmit: props.onSubmit,
    defaults: props.lic,
    toDisplay: [
      !props.lic ? {
        var: 'License #',
        title: 'License Number',
        ...required,
      } : null,
      {
        var: 'Licensee',
        title: 'Name',
        ...required,
      },
      {
        var: 'DBA',
        title: 'DBA',
        ...required,
      },
      {
        var: 'Street Address',
        title: 'Street Address',
        ...required,
      },
      {
        var: 'City',
        title: 'City',
        ...required,
      },
      {
        var: 'Zip',
        title: 'Zip',
        ...required,
      },
    ].filter(f => !!f)
  }

  const renderFooter = () => {
    return (
      <Row style={{ justifyContent: 'flex-end' }}>
        <Button title="Cancel" outline onClick={props.onCancel} />
        <Button title="Save" onClick={props.onOk} />
      </Row>
    )
  }

  return (
    <Modal {...props} footer={renderFooter()}>
      {!!props.visible && <Form {...formProps} registerForm={props.registerForm} />}
    </Modal>
  )
}