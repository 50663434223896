const PIXPERIN = 96;

export const inchesToPixes = inches => {
  return inches * PIXPERIN;
}

export const styles = {
  modal: `
    width: ${inchesToPixes(8.5)}px !important;
    height: ${inchesToPixes(11)}px !important;
    top: 0;
    margin: 0 !important;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    right: 0;

    .ant-modal-content {
      box-shadow: none;
      margin: 0 !important;
      padding: 0 !important;
    }

    .ant-modal-body {
      padding: 0px !important;
      margin: 0 !important;
      margin-left: 12px !important;
    }

    @media print {
      @page {
        margin: ${inchesToPixes(0.5)}px 0;
      } 
    }
  `,
  labelsContainer: `
    page-break-inside: avoid;
  `,
  labelContainer: `
    border: 2px solid black;
    margin: 0px 5px;
    padding: 10px;
    float: left;
    
    width: 3.98in;
    height: 3.32in;

    page-break-inside: avoid;
  `,
  logoContainer: `
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  textContainer: `
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  `,
  col: `
    padding: 5px;
  `,
  row: ``,
  rowTitle: `
    font-size: 12px;
    font-weight: bold;
  `,
  rowValue: `
    font-size: 10px;
  `,
  printLabelsBtn: `
    input {
      width: 60px;
      margin-left: 10px;
    }
  `,
  approvaldate: `
    position: absolute;
    top: 2.44in;
    width: 100%;
    left: 0px;
    text-align: center;
    font-size: 12px;
  `,
  crossout: `
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M100 0 L0 100 ' stroke='red' stroke-width='1'/><path d='M0 0 L100 100 ' stroke='red' stroke-width='1'/></svg>");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%, auto;
    border: solid red 2px;
  `,
  printErrorContainer: `
    position: absolute;
    top: 100%;
    width: 80%;
    background-color: white;
    left: 10%;
    text-align: center;
    font-weight: bold;
    padding: 10px;
    border: 1px solid black;
  `
}