/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { Select, AutoComplete, message } from 'antd';
import { getLicenseNumbers, getStoredLicenseNumbers, updateStoredLicenseNumbers } from '../services/api.service';
import { getUsers } from '../services/manage-users.service';
import { take, debounce, get, uniqBy, keyBy, set } from 'lodash';
import styles from './styles';
import { Button } from '../button';
import { AddressForm } from './address-form';

const ADD_NEW = 'ADD_NEW';

export default class LNSearch extends Component {
  state = {
    error: null,
    loading: true,
    licenseNumbersAll: [],
    licenseNumbers: [],
    allUsers: [],
    value: undefined,
    selectedOption: undefined,
    isNM: false,
    address: null,
    editAddress: {},
  };
  retrieve = async () => {
    try {
      const { accountsOnly } = this.props;
      const allLns = await getLicenseNumbers();

      const filterActive = get(this, 'props.filterActive', true);

      let licenseNumbersAll = []; 
      
      let allUsers = [];
      if (accountsOnly) {
        allUsers = await getUsers();
        const map = keyBy(allLns, 'License #');

        allUsers.map(u => {
          const company = get(u, 'data.company');
          if (company) {
            licenseNumbersAll.push(company);
            const additionalLNs = get(company, 'licenseNumbers');
            if (additionalLNs && additionalLNs.map) {
              additionalLNs.map(ln => {
                const comp = get(map, ln);
                if (comp) { licenseNumbersAll.push(comp) }
              })
            }
          }
        })
        licenseNumbersAll = uniqBy(licenseNumbersAll, 'License #');
      } else {
        licenseNumbersAll = allLns;
      }
      licenseNumbersAll = licenseNumbersAll.filter(f => !!get(f, 'License #'));
      const licenseNumbers = this.generateOptions(take(licenseNumbersAll, 100));
      this.setState({
        licenseNumbers,
        loading: false,
        allUsers,
        licenseNumbersAll: filterActive ? licenseNumbersAll.filter(f => !!get(f, 'active', true)) : licenseNumbersAll,
      });
    } catch (err) {
      console.error(err);
    }
  }
  componentDidMount = () => {
    this.retrieve();
  };
  componentDidUpdate = (prevProps, prevState) => {
    if(this.props.value !== prevProps.value && this.props.value !== this.state.value) {
      this.setState({
        value: this.props.value
      })
    }
    if (prevState.isNM !== this.state.isNM) {
      this.props.onNM && this.props.onNM(this.state.value);
    }
    if (this.props.accountsOnly !== prevProps.accountsOnly) {
      this.retrieve();
    }
  }
  generateOptions = (lns) => {
    const opts = lns.map(l => (
      <Select.Option
        key={l['License #']}
        value={l['License #']}
        name={`${l['Licensee']} - #${l['License #']}`}
      >
        <span>
          <span>{`${l["Licensee"]} - `}</span>
          <span css={css(`font-weight: bold;`)}>{`#${l['License #']}`}</span>
        </span>
      </Select.Option>
    ));

    if (this.props.allowEdit) {
      opts.unshift((
        <Select.Option
          key={ADD_NEW}
          value={ADD_NEW}
        >
          <span>
            <span style={{ fontWeight: 'bold' }}>{`Add New License`}</span>
          </span>
        </Select.Option>
      ))
    }

    return opts;
  }
  getSelected = () => {
    const { licenseNumbersAll, selectedOption, value } = this.state;

    return licenseNumbersAll.find(l => get(l, 'License #') === (selectedOption || value)) || {};
  }
  onChange = (value) => {
    const isNM = (value || '').toLowerCase().indexOf('nm-') > -1;
    this.setState({ value, isNM });
    if (isNM) {
      this.selectOption(value);
    }
  }
  onSearch = debounce((input) => {
    const { licenseNumbersAll } = this.state;

    this.setState({ loading: true });

    const val = input.toLowerCase();

    const licenseNumbers = licenseNumbersAll.filter(l => {
      const name = get(l, 'Licensee', '').toLowerCase();
      const ln = get(l, 'License #', '').toLowerCase();
      if (name.indexOf(val) > -1 || ln.indexOf(val) > -1) {
        return true;
      } else {
        return false;
      }
    })

    this.setState({
      licenseNumbers: take(this.generateOptions(licenseNumbers), 100),
      loading: false,
    });
  }, 800);
  findLic = (value) => {
    const lns = get(this.state, 'licenseNumbersAll', []);
    const lic = lns.find(l => get(l, 'License #') === value);

    const phone = '';// isEdit ? null : get(sState, 'user.company.phone', '');
    let text = ''

    if (lic) {
      const DBA = get(lic, 'DBA');
      const address = (() => {
        if (lic) {
          const street = get(lic, 'Street Address');
          const city = get(lic, 'City');
          const zip = get(lic, 'Zip');

          return [street, city, zip].filter(a => !!a).join(', ');
        }
      })(); 
      text = (address ? `Address: ${address}` : '') + (DBA ? `${address ? '- ' : ''}DBA: ${DBA}` : '') + (phone ? ` - Phone: ${phone}` : '');
    }

    return { lic, text }
  }
  selectOption = (val) => {
    const { onSelect } = this.props;

    const { lic, text } = this.findLic(val);

    if (val == ADD_NEW) {
      return this.setState({ selectedOption: null, value: null, address: null, lic: null }, this.setEditAddress);
    }

    this.setState({ 
      selectedOption: val, 
      value: val,
      address: text,
      lic,
    }, () => onSelect && onSelect(val));
  }
  setEditAddress = () => {
    let form;
    const close = () => {
      this.setState({ editAddress: { visible: false } });
    }

    const onSave = () => {
      return new Promise((resolve, reject) => {
        form.validateFieldsAndScroll(async (err, vals) => {
          try {
            if (err) { return reject(err); }
            const stored = await getStoredLicenseNumbers();
            const selLicense = vals['License #'] ? vals : this.state.lic;
            const licNum = get(selLicense, 'License #');
            
            set(stored, licNum, {
              ...get(stored, licNum, {}),
              ...vals,
            })
          
            await updateStoredLicenseNumbers(stored);
            await window.LOADLICENSE();
            await this.retrieve();

            this.selectOption(licNum);

            close();

            resolve(vals);
          } catch (err) {
            message.error(`Failed to update address: ${err.toString()}`);
            reject(err);
          }
        })
      })
    }

    this.setState({
      editAddress: {
        onClose: close,
        onCancel: close,
        onOk: onSave,
        onSubmit: onSave,
        visible: true,
        lic: this.state.lic,
        registerForm: f => form = f,
      }
    })
  }
  renderAddress = () => {
    const { address } = this.state;
    const { allowEdit } = this.props;

    return (
      <div  css={css(styles.subText)}>
        {address}
        {allowEdit && <Button onClick={this.setEditAddress} css={css(styles.editButton)} size="small" icon="edit"></Button>}
      </div>
    )
  }
  render() {
    const { loading, licenseNumbers, value, address } = this.state;
    const { showAddress } = this.props;

    const canAdd = get(this, 'props.canAdd', true);

    return (
      <div style={{ width: '100%' }}>
        <AutoComplete
          {...this.props}
          style={{ width: '100%' }}
          placeholder={'Type to filter results'}
          onSearch={this.onSearch}
          onSelect={this.selectOption}
          dataSource={canAdd ? licenseNumbers : undefined}
          onChange={this.onChange}
          value={value}
          loading={loading}
          optionLabelProp={'name'}
        />
        {showAddress && !!address && this.renderAddress()}
        <AddressForm {...this.state.editAddress} />
      </div>
    );
  }
};

LNSearch.defaultProps = {
  allowEdit: false,
  showAddress: false,
}