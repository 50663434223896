import colors from '../colors';

export default {
  subText: `
    background-color: ${colors.disabledLight};
    font-size: 12px;
    text-align: left;
    padding: 2px 5px;
  `,
  editButton: `
    position: absolute;
    right: 5px;
    font-size: 10px;
    padding: 0px 5px;
    height: 17px;
  `
}