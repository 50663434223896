/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import colors from '../colors';
import { Button } from 'antd';
import { noop } from 'lodash';

export class _Button extends Component {
  state = {
    loading: false,
  }
  onClick = async (e) => {
    try {
      const oclick = this.props.onClick || noop;
      this.setState({ loading: true })
      await oclick(e);
    } catch (err) {
      throw err;
    } finally {
      this.setState({ loading: false })
    }
  }
  render() {
    const { 
      title, 
      disabled, 
      outline, 
      children, 
      dark,
      type,
      hoverText,
    } = this.props;

    return (
      <Button
        loading={this.state.loading}
        {...this.props}
        onClick={this.onClick}
        title={hoverText || title}
        css={css(type === 'danger' ? '' : 
          `
            background-color: ${
              disabled
                ? colors.disabledColor
                : outline
                ? colors.white
                : dark
                ? colors.buttonDark
                : colors.highlight
            };
            border: 1px solid ${
            disabled
              ? colors.disabledColor
              : dark
                ? colors.buttonDark
                : colors.highlight
            };
          `,
          `
          color: ${
            disabled ? colors.white : outline ? colors.highlight : colors.white
          };
          font-size: 14px;
          cursor: pointer;
          border-radius: 0px;
        `,
          this.props.css
        )}
      >
        {title || children}
      </Button>
    );
  }
}
