/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import React, { useState, forwardRef, useRef } from 'react'
import { Button } from '../button';
import { size, get, set, uniqBy } from 'lodash';
import { Input, message, Modal } from 'antd';
import { styles } from './styles';
import { getLicAddress, getRawLicense, jparse, PageMedia, stopProp, wait, getConfig } from '../../actions/utils';
import Logo from '../logo';
import QRCode from 'qrcode'
import Config from '../../config';
import moment from 'moment';
import { getOrderStatus } from '../../models/order';

const config = Config();

const ID = "print-modal";

export const pullAmtOutOfOrder = (order) => {
  const boxcount = get(order, 'data.order.weight', 1);
  const amt = parseInt(boxcount);
  return isNaN(amt) ? 1 : amt;
}

export const PrintLabelsButton = props => {
  const { order } = props;
  const printRef = useRef();

  const defAmt = pullAmtOutOfOrder(order);

  const [state, setState] = useState({
    amt: defAmt,
    orders: [props.order],
    loading: false,
  })

  const changeAmt = e => {
    stopProp(e);
    const { value } = e.target;
    set(state, 'orders.0.data.order.weight', value);
    set(state, 'amt', value);
    setState({ ...state });
  }

  const onClick = async () => {
    try {
      const method = get(printRef, 'current.printOrders', async () => 0);
      setState(s => ({ ...s, loading: true }));
      await method();
    } catch (err) {
      
    } finally {
      setState(s => ({ ...s, loading: false }));
    }
  }

  return (
    <>
      <Button loading={state.loading} onClick={onClick} css={css(styles.printLabelsBtn)}>
        Print Labels
        <Input size="small" onClick={e => stopProp(e)} value={state.amt} type="number" onChange={changeAmt} />
      </Button>
      <PrintLabels showButton={false} ref={printRef} orders={state.orders} />
    </>
  )
}

export const PrintLabels = forwardRef((props, ref) => {
  // const { orders } = props;
  const [state, setState] = useState({
    showPrint: false,
    orders: props.orders || [],
    codes: [],
  });

  const printOrders = async () => {
    try {
      const orders = [];

      uniqBy((props.orders || []), 'id').map((order) => {
        const amt = pullAmtOutOfOrder(order);
        for (let i = 0; i < amt; i++) {
          orders.push({ ...order, _boxCount: { val: i + 1, total: amt } })
        }
      })

      setState(s => ({ ...s, showPrint: true, orders }));

      const pm = PageMedia();

      pm.insert(`
        size: portrait;
        margin: 0.5in 0;
      `)

      const codes = await Promise.all(orders.map(o => QRCode.toDataURL(`${config.appUrl}/order/${o.id}`)));
      setState(s => ({ ...s, codes }));

      await wait(500);
      const elem = document.getElementsByClassName("print-modal")[0];
      elem.style.position = 'absolute';
      elem.style.overflow = 'visible';
      window.print();
    } catch (err) {
      message.error(`Failed to print: ${err.message}`);
    } finally {
      setState(s => ({ ...s, showPrint: false }))
    }
  };

  const close = () => {
    setState(s => ({ ...s, showPrint: false }));
  }

  set(ref, 'current.printOrders', printOrders);

  const showButton = get(props, 'showButton', true);

  return (
    <>
      {!!size(props.orders) && showButton && <Button title="Print Labels" hoverText={'To print multiple labels for one order, please select the individual order and enter the number of labels you would like to print in the “Print Labels” button.'} onClick={printOrders}></Button>}
      <Modal 
        visible={state.showPrint}
        onOk={close}
        onCancel={close}
        mask={false}
        css={css(styles.modal)}
        footer={null}
        closable={false}
        id={ID}
        wrapClassName={ID}
      >
        <div css={css(styles.labelsContainer)}>
          {(state.orders || []).map((order, i) => <LabelRow key={i} order={order} code={state.codes[i]} />)}
        </div>
      </Modal>
    </>
  )
})

const getAndFormat = (row, keys, join = '<br />') => {
  let str = [];
  keys.map(k => {
    const val = get(row, k);
    if (val !== undefined) {
      str.push(val);
    }
  })
  return str.join(join);
}

const LabelRow = props => {
  const { order, code } = props;

  const PICKLN = get(order, 'data.order.pickupLN');
  const DESTLN = get(order, 'data.order.destinationLN');
  const route = get(order, 'data.order.route', '{}');
  const bcount = get(order, '_boxCount');
  const { canPrintLabel, title, allowedStatus } = getOrderStatus(order);

  const pick = getRawLicense(PICKLN, {});
  const dest = getRawLicense(DESTLN, {});

  const WIDTHS = [40, 60];

  const COLS = !canPrintLabel ? [
    [
      { title: 'ORDER ID', key: 'orderId' },
    ],
  ] : [
    [
      { title: 'ORDER ID', key: 'orderId' },
      { title: 'VENDOR', val: `${PICKLN}<br />${getAndFormat(pick, ['Licensee', 'DBA'])}` },
      { title: 'SHIP TO', val: `${getAndFormat(dest, ['Licensee', 'DBA'])}<br />${getLicAddress(DESTLN) || 'N/A'}` },
      { title: 'BOX COUNT', val: bcount ? `${get(bcount, 'val')} of ${get(bcount, 'total')}` : '1 of 1' },
    ],
    [
      { title: 'ROUTE', val: get(jparse(route), 'name', 'N/A'), style: `font-size: 18px; line-height: 18px; font-weight: bold;` },
      { title: '', val: (<div css={css(styles.qrContainer)}><img src={code} /></div>) }
    ]
  ]

  const ltype = getConfig('logoType', 'orig');

  return (
    <div css={css(styles.labelContainer, !canPrintLabel && styles.crossout)}>
      <div css={css(styles.logoContainer)}><Logo small={ltype === 'new' ? false : true} width={ltype === 'new' ? 70 : 130} /></div>
      <div css={css(styles.textContainer)}>
        {COLS.map((col, i) => {
          return (
            <div key={i} css={css(`width: ${WIDTHS[i]}%;`, styles.col)}>
              {col.map(c => {
                const val = c.val || get(order, c.key, 'N/A');
                const ishtml = typeof val == 'string' ? val : undefined
                return (
                  <div key={c.key} css={css(styles.row)}>
                    <div css={css(styles.rowTitle)}>{`${c.title}`}</div>
                    {ishtml ? <div css={css(styles.rowValue, c.style)} dangerouslySetInnerHTML={{ __html: val }} /> : <div css={css(styles.rowValue, c.style)}>{val}</div>}
                  </div>
                )
              })}
            </div>
          )
        })}
        
        {canPrintLabel && <div css={css(styles.approvaldate)}>{`APPROVED DELIVERY DATE: ${!get(order, 'data.order.deliveryDate') ? 'N/A' : moment(get(order, 'data.order.deliveryDate')).format('L')}`}</div>}

        {!canPrintLabel && <div css={css(styles.printErrorContainer)}>
          <p>{`!!SHIPPING LABEL ERROR!!`}</p>
          <br /><br />
          <p>{`PLEASE CHECK ORDER STATUS:`}</p>
          <p>{`Current Status: (${title})`}</p>
          <p>{`Allowed Status: (${allowedStatus.title})`}</p>
        </div>}
      </div>
    </div>
  )
}