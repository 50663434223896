/** @jsx jsx */
import { css, Global, jsx } from '@emotion/core';
import { useState, useEffect, useRef, forwardRef } from 'react';
import moment from 'moment';
import { getDrivers } from '../../../_shared/services/drivers.service';
import { getVehicles } from '../../../_shared/services/vehicles.service';
import { getRoutes } from '../../../_shared/services/routes.service';
import { getDriverSchedule, updateDriverSchedule } from '../../../_shared/services/driverschedule.service';
import { get, set, size, flatten, uniq } from 'lodash';
import styles, { Globals } from '../styles';
import { Button } from '../../../_shared/button';
import { Icon, message, Modal, Select, DatePicker, Checkbox } from 'antd';
import { EditableFormTable } from '../../../_shared/editable-table';
import { DateTool, guid } from '../../../actions/utils';
import { CalSelect } from '../../../_shared/calselect';
import { ExtraEditRender, ExtraRender } from '../../../_shared/editable-table/templates';
import { genDriverGuid } from '../../../_shared/driver-search';
import { fixOrdersAndNotify } from '../../../_shared/services/orders.service';
import { useAuth } from '../../../_shared/hooks/auth';
import { TableList } from '../../../_shared/table-list';
import { useScreen } from '../../../_shared/hooks/useScreen';

//#region utils
export const getStartOfWeek = (date = undefined) => {
  return moment(date).utc().startOf('week');
}

export const Days = {
  '1': 'mon',
  '2': 'tue',
  '3': 'wed',
  '4': 'thur',
  '5': 'fri',
  '6': 'sat',
  '7': 'sun',
};

export const getNumAsDay = num => {
  const days = Days;
  return get(days, num);
}

export const getDayAsNumber = (date) => {
  return moment(date).startOf('day').utc().isoWeekday();
}

export const getRoutesWithUtilities = (routes) => {
  const uIndex = routes.findIndex(f => get(f, 'name', '').toLowerCase().trim() === 'utility');
  
  let _routes = routes;
  
  if (uIndex > -1) {
    _routes = [
      ...routes,
      ...get(routes, `${uIndex}.data.rows`, []).map(r => ({ ...r, name: r.city })),
    ];
  
    _routes.splice(uIndex, 1);
  }

  return _routes;
}

const blankDays = (fillWith = []) => {
  return {
    1: fillWith, //mon
    2: fillWith,
    3: fillWith,
    4: fillWith,
    5: fillWith,
    6: fillWith,
    7: fillWith //sun
  }    
}
//#endregion

const ObjIndexes = {
  vehicle: 1,
  route: 0,
  sixam: 2,
}

const EXTRAS = [
  { title: 'Backhaul', id: 'backHaul', type: 'boolean' },
  { title: 'Training', id: 'training', type: 'boolean' },
  { title: 'Cancelled', id: 'cancelled', type: 'boolean' },
  { title: 'Notes', id: 'notes', type: 'textarea' },
]

const EditCell = props => {
  const state = useRef([]).current;
  const { cell, schedule } = props;

  const di = get(cell, 'dataIndex');

  const changeValue = (val, key) => {
    set(state, key, val);
    cell.setFieldsValue({
      [di]: state,
    });
  }

  const getDefault = (key) => {
    return get(cell, `record.${di}.${key}`);
  }

  const addBlank = (items) => {
    return [{ id: undefined, name: 'None' }, ...items]
  }

  useEffect(() => {
    const vals = get(cell, `record.${di}`, []) || [];
    vals.map((v, i) => changeValue(v, i));
  }, []);

  const currentDayRoutes = get(schedule, `data.${di}`, []).filter(f => f.route).map(f => f.route);
  const currentDayVehicles = get(schedule, `data.${di}`, []).filter(f => f.vehicle).map(f => f.vehicle);

  const occurences = (arr, v) => {
    return arr.filter(f => f === v.id);
  }

  const routes = (() => {
    const [, day] = di.split('.');
    const dayText = getNumAsDay(day);
    const prevDay = getNumAsDay(day - 1);
    const filtered = props.routes.filter(route => {
      const isUtil = get(route, 'zipCode', '') === '00000';
      const rows = get(route, 'data.rows', []);

      const allDays = uniq(flatten(rows.map(r => get(r, 'dayOfTheWeek', []))));
      const dayNums = allDays.filter(f => typeof f == 'number');
      const weekDays = allDays.filter(f => typeof f == 'string');
      
      const dateNum = DateTool.dateToNum(moment(props.week).add(parseInt(day) + 1, 'day'));
      
      const dayOfWeek = weekDays.indexOf(dayText) > -1;
      const dayOfWeekNum = dayNums.indexOf(dateNum) > -1;

      const isOvernight = get(route, 'data.rows', []).find(r => get(r, 'overnight.0') === 'true' && get(r, 'dayOfTheWeek', []).indexOf(prevDay) > -1)
      return isUtil || dayOfWeek || dayOfWeekNum || isOvernight;
    })
    return addBlank(filtered).map(v => {
      const oc = occurences(currentDayRoutes, v);
      return <Select.Option className={size(oc) >= 2 ? 'doubleBooked' : size(oc) >= 1 ? 'alreadySelected' : ''} value={v.id}>{v.name}</Select.Option>
    })
  })();

  return (
    <div css={css(styles.buffer)}>
      <Select 
        defaultValue={getDefault(ObjIndexes.route)} 
        placeholder="Route" 
        onChange={e => changeValue(e, ObjIndexes.route)} 
        css={css(styles.select)}
        dropdownClassName={'dropdownContainer'}
      >
        {routes}
      </Select>
      <Select 
        defaultValue={getDefault(ObjIndexes.vehicle)} 
        placeholder="Vehicle" 
        onChange={e => changeValue(e, ObjIndexes.vehicle)} 
        css={css(styles.select)}
        dropdownClassName={'dropdownContainer'}
      >
        {addBlank(props.vehicles).map(v => {
          const oc = occurences(currentDayVehicles, v);
          return (
            <Select.Option className={size(oc) >= 2 ? 'doubleBooked' : size(oc) >= 1 ? 'alreadySelected' : ''} value={v.id}>{v.id && !!get(v, 'data.maint') ? <Icon css={css(styles.yellowIcon)} type="setting" /> : null}{!v.id ? v.name : `${v.name} - ${v.lic}`}</Select.Option>
          )
        })}
      </Select>

      <ExtraEditRender {...props} cell={{ ...cell, dataIndex: `${di}.2` }} onChange={(val, key) => changeValue(val, `2.${key}`)} extras={EXTRAS} />
    </div>
  )
}

const CellRender = props => {
  const { text, vehicles, routes } = props;
  const vehic = vehicles.find(v => v.id === get(text, ObjIndexes.vehicle));
  const vehicle = vehic && `${get(vehic, 'name')} - ${get(vehic, 'lic')}`;
  const route = routes.find(v => v.id === get(text, ObjIndexes.route));
  const routename = get(route, 'name');

  const invalid = !vehicle || !route;
  const isTraining = get(text, '2.training');
  const startTime = get(route, 'data.startTime');
  const isSix = startTime == '06:00';
  const isCancelled = !!get(text, '2.cancelled');

  return (
    <div css={css(invalid && styles.invalid, isSix && styles.isSix, isTraining && styles.isTraining, isCancelled && styles.isCancelled)}>
      <div css={css(styles.buffer)}>
        <div>{`Route: ${routename || 'none'}`}</div>
        <div>{`Vehicle: ${vehicle || 'none'}`}</div>
        <div>{`Start Time: ${startTime ? moment(startTime, ["h:mm A"]).format('h:mma') : 'N/A'}`}</div>
        <ExtraRender record={{ data: get(text, '2') }} extras={EXTRAS} />
      </div>
    </div>
  )
}

export const COLS = (props) => [
  {
    title: 'Driver',
    dataIndex: 'driver',
    width: '25%',
    editable: false,
    sorter: (_a, _b) => {
      try {
        const a = get(props.drivers.find(d => d.id == get(_a, 'driver')), 'name');
        const b = get(props.drivers.find(d => d.id == get(_b, 'driver')), 'name');
        return a.localeCompare(b, 'en', { numeric: true })
      } catch (err) {
        return 0;
      }
    },
    render: (text, record) => {
      const driver = get(props, 'drivers', []).find(f => f.id == text);
      return (
        <div css={css(styles.buffer, styles.column)}>
          <div css={css(styles.bold)}>{get(driver, 'name', 'none')}</div>
          <div>{get(driver, 'occLic', 'none')}</div>
          <div>{get(driver, 'lic', 'none')}</div>
        </div>
      )
    }
  },
  {
    title: 'Monday',
    dataIndex: 'days.1',
    width: '15%',
    editable: true,
    customInput: (_this) => <EditCell cell={_this} {...props} />,
    render: (text, record) => <CellRender {...{ text, record, ...props }} />
  },
  {
    title: 'Tuesday',
    width: '15%',
    dataIndex: 'days.2',
    editable: true,
    customInput: (_this) => <EditCell cell={_this} {...props} />,
    render: (text, record) => <CellRender {...{ text, record, ...props }} />
  },
  {
    title: 'Wednesday',
    width: '15%',
    dataIndex: 'days.3',
    editable: true,
    customInput: (_this) => <EditCell cell={_this} {...props} />,
    render: (text, record) => <CellRender {...{ text, record, ...props }} />
  },
  {
    title: 'Thursday',
    width: '15%',
    dataIndex: 'days.4',
    editable: true,
    customInput: (_this) => <EditCell cell={_this} {...props} />,
    render: (text, record) => <CellRender {...{ text, record, ...props }} />
  },
  {
    title: 'Friday',
    width: '15%',
    dataIndex: 'days.5',
    editable: true,
    customInput: (_this) => <EditCell cell={_this} {...props} />,
    render: (text, record) => <CellRender {...{ text, record, ...props }} />
  },
]

export const Schedule = forwardRef((props, ref) => {
  const { isMobile } = useScreen();

  const { loggedIn, userHasRole } = useAuth();

  const [state, setState] = useState({
    drivers: [],
    vehicles: [],
    routes: [],
    loading: false,
    printMode: false,
    week: getStartOfWeek(),
    changes: {},
    notifyUsers: false,
  });

  const isDriver = userHasRole(2);
  
  const schedule = useRef();

  const setSchedule = (obj) => {
    set(schedule, 'current', obj);
  }
  const getSchedule = (obj) => {
    return get(schedule, 'current');
  }

  const convertSchedule = (data, toRows = true) => {
    if (!data && toRows) {
      data = {
        id: guid(),
        date: state.week.toISOString(),
        data: {
          days: blankDays(),
        }
      }
    }

    //torows tells us if the data being passed in is schedule format or rows
    if (toRows) {
      setSchedule(data);
      const _drivers = {};
  
      const days = get(data, 'data.days', {});
      Object.keys(days).map(k => {
        get(days, k, []).map(dsched => {
          const { driver } = dsched;

          set(_drivers, driver, {
            driver,
            days: {
              ...get(_drivers, `${driver}.days`, {}),
              [k]: [
                get(dsched, 'route'),
                get(dsched, 'vehicle'), 
                get(dsched, 'misc', {})
              ],
            }
          });
        })
      })

      return Object.keys(_drivers).map(dkey => _drivers[dkey]);
    } else {
      //data is expected to be array of driver rows, so we need to convert that into the schedule format
      const ret = { ...getSchedule() };
      set(ret, 'data.days', blankDays());
      data.map(row => {
        const { driver, days } = row;
        
        Object.keys(days).map(k => {
          const dayarr = get(days, k, []);
          const key = `data.days.${k}`;
          const [route, vehicle, misc] = dayarr || [];
          set(ret, key, [
            ...get(ret, key, []),
            {
              driver,
              vehicle,
              route, 
              misc,
            }
          ])
        })
      })
      return ret;
    }
  }

  const [selectDriver, setSelectDriver] = useState({ visible: false });
  const [notifyOrders, setNotifyOrders] = useState({ visible: false });

  const [rows, setRows] = useState([]);

  const getData = async () => {
    try {
      setState(s => ({ ...s, loading: true }));
      
      const [drivers, vehicles, routes, sched] = await Promise.all([
        await getDrivers(),
        await getVehicles(),
        await getRoutes(),
        await getDriverSchedule({ date: state.week.toISOString() }),
      ])

      setState(s => ({ 
        ...s, 
        loading: false, 
        drivers, 
        vehicles, 
        routes: getRoutesWithUtilities(routes),
      }));
      setRows(convertSchedule(sched));
    } catch (err) {
      alert(err.message);
      setState(s => ({ ...s, loading: false }));
    }
  }

  const setupRow = driver => {
    setRows([
      ...rows,
      {
        driver: driver.id,
        days: {
          1: null,
          2: null,
          3: null,
          4: null,
          5: null,
          6: null,
          7: null
        }
      }
    ])
  }

  const addRow = async e => {
    return new Promise((resolve) => {
      let val;

      const close = () => {
        setSelectDriver({ visible: false });
        resolve();
      }

      const validDrivers = state.drivers.filter(d => !rows.find(r => get(r, 'driver') == d.id));

      setSelectDriver({
        visible: true,
        title: 'Select Driver',
        children: (
          <Select placeholder="Select Driver" style={{ width: '100%' }} onChange={e => val = e}>
            {validDrivers.map(d => <Select.Option value={d.id}>{d.name}</Select.Option>)}
          </Select>
        ),
        onOk: () => {
          if (!val) {
            close();
            return message.error('Please first select a valid driver');
          }

          const driver = state.drivers.find(d => d.id === val);

          setupRow(driver);

          close();
        },
        onCancel: () => {
          close();
        }
      })
    })
  }

  const updateData = (d, rowUpdated, rowIndex) => {
    message.warning('Changes made. Make sure to hit Notify Button!')
    
    const oldRowObj = get(rows, `${rowIndex}.days`, {}) || {};

    const changes = { ...state.changes };

    Object.keys(oldRowObj).map(dayIndex => {
      const [newRoute, newVehicle] = get(rowUpdated, `days.${dayIndex}`, []) || [];
      const [oldRoute, oldVehicle] = get(oldRowObj, dayIndex, []) || [];

      if (newRoute !== oldRoute || newVehicle !== oldVehicle) {
        const driver = get(rows, `${rowIndex}.driver`);
        const prevChange = get(state, ['changes', rowIndex, dayIndex, 'oldHash']);
        const newHash = genDriverGuid({ driver, vehicle: newVehicle, route: newRoute });
        if (prevChange === newHash) {
          set(changes, [rowIndex, dayIndex], undefined);
        } else {
          set(changes, [rowIndex, dayIndex], {
            dayIndex,
            oldHash: prevChange || genDriverGuid({ 
              driver,
              vehicle: oldVehicle,
              route: oldRoute,
            }),
            newHash,
          })
        }
      }
    })

    setState(s => ({ ...s, changes }))
    setRows(d);
  }

  const updateChangedOrders = () => {
    const _close = () => {
      setNotifyOrders({ visible: false });
    }

    setNotifyOrders({
      visible: true,
      title: 'Update Schedule',
      onCancel: () => {
        _close();    
      }
    })
  }

  const saveData = async () => {
    const sched = getSchedule();
    if (sched.id) {
      await updateDriverSchedule(getSchedule());
    }
  }

  const selectWeek = (a) => {
    const week = getStartOfWeek(a);
    setState(s => ({ ...s, week }));
  }

  const onCalSelect = async d => {
    const dateSelected = getStartOfWeek(d);
    Modal.confirm({
      title: 'Are you sure?',
      content: `You have selected the week of ${dateSelected.format('L')}. If you hit ok, anything saved on that week will be overwritten.`,
      onCancel: () => 0,
      onOk: async () => {
        const sched = { 
          ...getSchedule(),
          id: guid(),
          date: dateSelected,
        };
        await updateDriverSchedule(sched);
        setState(s => ({ ...s, week: dateSelected }));
      }
    })
  }

  const print = () => {
    window.print();
    setState(s => ({ ...s, printMode: false }))
  }

  set(ref, 'current.getData', getData);

  useEffect(() => { getData() }, [state.week]);
  useEffect(() => { 
    setSchedule(convertSchedule(rows, false));
    saveData();
  }, [rows]);

  useEffect(() => {
    if (state.printMode) { print() }
  }, [state.printMode]);

  const Tab = loggedIn && !isDriver ? EditableFormTable : TableList;

  const hasChanges = !!size(Object.keys(state.changes));

  const tableProps = {
    scroll: state.printMode ? undefined : { x: isMobile ? 800 : 1000 }
  }

  return (
    <div css={css(styles.table)}>
      <Global styles={Globals} />
      <div className="no-print" css={css(styles.calSelectContainer)}>
        <DatePicker.WeekPicker style={{ width: 200 }} value={state.week} placeholder="Select Week" onChange={selectWeek} />
        {loggedIn && userHasRole(0) && <CalSelect 
          title="Copy current schedule to selected week"
          buttonProps={{ icon: "copy" }} onSubmit={onCalSelect} 
        />}
        
        {loggedIn && hasChanges && <Button
          onClick={() => updateChangedOrders()}
          css={css(styles.printOnly, 'right: 50px;')}
          className="no-print"
          type="primary"
          icon={state.loading ? "loading" : "cloud-upload"}
        >Update Schedule</Button>}

        <Button
          onClick={() => setState(s => ({ ...s, printMode: !state.printMode }))}
          css={css(styles.printOnly)}
          className="no-print"
          type="primary"
          icon="printer"
        />
      </div>

      <Tab 
        data={rows}
        columns={COLS({ ...state, schedule: schedule.current })}
        updateData={updateData}
        addRow={addRow}
        rowKey={'driver'}
        addButton={'Add Driver'}
        loading={state.loading}
        canSelectRows={false}
        implementScroll={false}
        onRefresh={() => getData()}
        tableProps={tableProps}
        {...tableProps}
      />

      <Modal {...selectDriver} />

      <Modal 
        {...notifyOrders}
        onOk={async () => {
          try {
            setState(s => ({ ...s, loading: true }));
        
            let hashes = [];
            Object.keys(state.changes).map(rowIndex => {
              state.changes[rowIndex].map(roi => {
                if (roi) {
                  hashes = [...hashes, roi];
                }
              })
            });
        
            await fixOrdersAndNotify({ week: state.week, hashes, notify: state.notifyUsers })
            setState(s => ({ ...s, changes: {} }));
          } catch (err) {
            message.error(`Update failed: ${err.message}`);
          } finally {
            setState(s => ({ ...s, loading: false }));
            setNotifyOrders({ visible: false });
          }
        }}
      >
          <div>
            <b>Do you want to also notify users that their drivers have changed?</b>
            <br /><br />
            <Checkbox checked={state.notifyUsers} onChange={() => setState(s => ({ ...s, notifyUsers: !s.notifyUsers }))}>Notify Users</Checkbox>
          </div>
      </Modal>
    </div>
  )
})