import axios from 'axios';
import getConfig from '../../config';
import { get, set } from 'lodash';

const getStore = () => {
  return get(window, 'STORE', { getState: () => {} });
};

const createInstance = () => {
  const config = getConfig();
  return axios.create({
    baseURL: `${config.url}/api`,
  });
};

export let instance = createInstance();

const getCurrentToken = () => {
  const store = getStore();
  const sState = store.getState();
  return get(sState, 'user.token', '');
};

instance.interceptors.request.use(
  config => {
    if (!config.url.includes('/users/token')) {
      if (!get(config, 'headers.Authorization')) {
        set(config, 'headers.Authorization', `Bearer ${getCurrentToken()}`);
      }
    }

    return config;
  },
  error => {
    throw Promise.reject(error);
  }
);

let loggingOut = false;
instance.interceptors.response.use(
  value => {
    return value;
  },
  async err => {
    const status = get(err, 'response.status');
    // const error = new Error(err);
    if (status == 401 && !loggingOut) {
      loggingOut = true;
      await localStorage.clear();
      alert('Your token is no longer valid. Please login again.');
      window.location.href = window.location.origin;
      setTimeout(() => window.location.reload(), 50);
    }

    throw err;
  }
);

export const getLicenseNumbers = async (useLocal = true) => {
  const LNS = get(window, 'KEYMAPS.license');
  if (LNS && Object.keys(LNS).length && useLocal) {
    return Object.keys(LNS).map(k => LNS[k]);
  }
  const resp = await instance.get(`/license/licensenumbers`, { data: {} });
  return get(resp, 'data.data.licensenumbers', []);
};

export const updateSpreadsheet = async () => {
  const resp = await instance.post('/sheet/updateSheet', { data: {} });
  return get(resp, 'data', {});
}

export const getStoredLicenseNumbers = async () => {
  const resp = await instance.get(`/license/lnstored`, { data: {} });
  return get(resp, 'data.data', []);
};

export const updateStoredLicenseNumbers = async (data) => {
  const resp = await instance.post(`/license/updatestored`, data);
  return get(resp, 'data.data', []);
};